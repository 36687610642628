:root{
  --white: #F5F6FA;
  --pureWhite : #FFFFFF;
  --black : #707070;
  --dark : #2D3436;
  --yellow : #FDCB6E;
  --blue : #74B9FF;
  --grey :#9E9E9F;
  --mainTransition : all 0.3s ease-in-out;
  --mainRGBA : rgba(0,0,0,0.4)
  

}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
body{
  font-family: 'Raleway', sans-serif !important;
  background: var(--white);
  color: var(--black)

}

/* <style>.cls-1{fill:#231f20;}.cls-2{fill:none;stroke:#f7941d;stroke-miterlimit:10;stroke-width:3px;}</style> */
.cls-1{
  fill:#231f20;
}
.cls-2{
  fill:none;stroke:var(--blue);stroke-miterlimit:10;stroke-width:3px;
}
/* .barSvg2{
  fill:none;stroke:#f7941d;stroke-miterlimit:10;stroke-width:3px;
  width: 400px;
}
.barSvg{fill:#231f20;} */
